// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_pagination__yA94b {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: fit-content;
}

.styles_prev__i6S1f {
    padding: 6px 16px;
    border: 1px solid rgba(226, 232, 240, 1);
    font-weight: 600;
    color: #1A202C;
    border-radius: 6px 0 0 6px;
    cursor: pointer;
}

.styles_next__3S2YH {
    padding: 6px 16px;
    border: 1px solid rgba(226, 232, 240, 1);
    font-weight: 600;
    color: #1A202C;
    border-radius: 0 6px 6px 0;
    cursor: pointer;
}

.styles_middle__Ji\\+ao {
    padding: 6px 12px;
    border: 1px solid rgba(226, 232, 240, 1);
    font-weight: 600;
    color: #1A202C;
    cursor: pointer;
}

.styles_extending__eFSTX {
    letter-spacing: 4px;
    color: rgba(0, 0, 0, 0.64);
    padding-left: 12px;
    padding-right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/homeApp/pagination/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,wCAAwC;IACxC,gBAAgB;IAChB,cAAc;IACd,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,wCAAwC;IACxC,gBAAgB;IAChB,cAAc;IACd,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,wCAAwC;IACxC,gBAAgB;IAChB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,0BAA0B;IAC1B,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".pagination {\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    height: fit-content;\n}\n\n.prev {\n    padding: 6px 16px;\n    border: 1px solid rgba(226, 232, 240, 1);\n    font-weight: 600;\n    color: #1A202C;\n    border-radius: 6px 0 0 6px;\n    cursor: pointer;\n}\n\n.next {\n    padding: 6px 16px;\n    border: 1px solid rgba(226, 232, 240, 1);\n    font-weight: 600;\n    color: #1A202C;\n    border-radius: 0 6px 6px 0;\n    cursor: pointer;\n}\n\n.middle {\n    padding: 6px 12px;\n    border: 1px solid rgba(226, 232, 240, 1);\n    font-weight: 600;\n    color: #1A202C;\n    cursor: pointer;\n}\n\n.extending {\n    letter-spacing: 4px;\n    color: rgba(0, 0, 0, 0.64);\n    padding-left: 12px;\n    padding-right: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `styles_pagination__yA94b`,
	"prev": `styles_prev__i6S1f`,
	"next": `styles_next__3S2YH`,
	"middle": `styles_middle__Ji+ao`,
	"extending": `styles_extending__eFSTX`
};
export default ___CSS_LOADER_EXPORT___;
