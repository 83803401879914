// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__tDAdy {
    border: none;
    outline: none;
    font-size: 16px;
    border-radius: 6px;
    cursor: pointer;
}

.styles_solid__efQfQ {
    padding: 8px 16px;
    color: #fff;
    background-color: rgba(56, 161, 105, 1);
}

.styles_outline__FwC0D {
    padding: 6px 15px;
    color: rgba(56, 161, 105, 1);
    background-color: transparent;
    border: 1px solid rgba(56, 161, 105, 1);
}

.styles_disable__6jmlS {
    pointer-events: none;  
}

.styles_disable__6jmlS button {
    z-index: 10;
    background-color: rgba(105, 156, 129, .5);
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/button/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,uCAAuC;AAC3C;;AAEA;IACI,iBAAiB;IACjB,4BAA4B;IAC5B,6BAA6B;IAC7B,uCAAuC;AAC3C;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,WAAW;IACX,yCAAyC;AAC7C","sourcesContent":[".button {\n    border: none;\n    outline: none;\n    font-size: 16px;\n    border-radius: 6px;\n    cursor: pointer;\n}\n\n.solid {\n    padding: 8px 16px;\n    color: #fff;\n    background-color: rgba(56, 161, 105, 1);\n}\n\n.outline {\n    padding: 6px 15px;\n    color: rgba(56, 161, 105, 1);\n    background-color: transparent;\n    border: 1px solid rgba(56, 161, 105, 1);\n}\n\n.disable {\n    pointer-events: none;  \n}\n\n.disable button {\n    z-index: 10;\n    background-color: rgba(105, 156, 129, .5);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__tDAdy`,
	"solid": `styles_solid__efQfQ`,
	"outline": `styles_outline__FwC0D`,
	"disable": `styles_disable__6jmlS`
};
export default ___CSS_LOADER_EXPORT___;
