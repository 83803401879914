import React from "react";
import { Row, Col} from 'react-bootstrap';
import Heading from "../../../../components/shared/Heading";
import hederaLogo from "../../../../../assets/icons/Hedera logo.svg";
import styles from "./style.module.css";

const Traceability = () => {
    const divStyle = {width: "100%",
    height: 40,
    backgroundColor: "#F7FAFC",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",};
    return (
        <>
            <Row>
                <Col lg={3}><Heading text="Traceability"/></Col>
                <Col lg={4}>
                    <div style={divStyle}>
                        <img src={hederaLogo} alt="Hedera Logo" />
                        <p className={styles.lightText}>Powered by</p>
                        <p className={styles.darkText}>Hedera</p>
                    </div>
                </Col>
                <Col lg={4}></Col>
            </Row>
            <Row>
                <Col lg={6} className={styles.rowStyle}>
                    <Row>
                    <Col lg={6} className={styles.paddingCol}><span className={styles.headingText}>Approx. date of sowing</span></Col><Col lg={6} className={styles.paddingCol}><span className={styles.valueText}>02 Jan 2023</span></Col>
                    <Col lg={6}  className={styles.paddingCol}><span className={styles.headingText}>Approx. date of harvesting</span></Col><Col  lg={6} className={styles.paddingCol}><span className={styles.valueText}>06 Feb 2023</span></Col>
                    <Col lg={6} className={styles.paddingCol}><span className={styles.headingText}>Approx. date of picking</span></Col><Col  lg={6} className={styles.paddingCol}><span className={styles.valueText}>02 Apr 2023</span></Col>
                    <Col lg={6} className={styles.paddingCol}><span className={styles.headingText}>Segregation done on</span></Col><Col  lg={6} className={styles.paddingCol}><span className={styles.valueText}>02 May 2023</span></Col>
                    <Col lg={6} className={styles.paddingCol}><span className={styles.headingText}>Grading done on</span></Col><Col  lg={6} className={styles.paddingCol}><span className={styles.valueText}>12 May 2023</span></Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default Traceability;