// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#dnds div:has(input[type="file"]){
  padding: 20px!important; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;      

 }

 #adr div:has(input),
 #adr.MuiFormControl-root :has(textarea){
     width: 100% !important;
    }

    #adr.MuiFormControl-root :has(textarea){
        min-width: 276px !important;
    }

 #flexdiv{
    justify-content: center;
    flex-wrap: wrap;
 }
 
 #selector{
    min-width: 267px;
 }

 @media only screen and (max-width:670px){
  #acc_holder div:has(input){
      width: 100%;
  }
  #flexwrapper{  
      flex-wrap: wrap;
    gap: 20px;
    & div:has(input) {
        width: 100%;
    
}
  }


}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/RegistrationPage/AddressDetails/addressDetails.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;CAEpB;;CAEA;;KAEI,sBAAsB;IACvB;;IAEA;QACI,2BAA2B;IAC/B;;CAEH;IACG,uBAAuB;IACvB,eAAe;CAClB;;CAEA;IACG,gBAAgB;CACnB;;CAEA;EACC;MACI,WAAW;EACf;EACA;MACI,eAAe;IACjB,SAAS;IACT;QACI,WAAW;;AAEnB;EACE;;;AAGF","sourcesContent":["#dnds div:has(input[type=\"file\"]){\n  padding: 20px!important; \n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;      \n\n }\n\n #adr div:has(input),\n #adr.MuiFormControl-root :has(textarea){\n     width: 100% !important;\n    }\n\n    #adr.MuiFormControl-root :has(textarea){\n        min-width: 276px !important;\n    }\n\n #flexdiv{\n    justify-content: center;\n    flex-wrap: wrap;\n }\n \n #selector{\n    min-width: 267px;\n }\n\n @media only screen and (max-width:670px){\n  #acc_holder div:has(input){\n      width: 100%;\n  }\n  #flexwrapper{  \n      flex-wrap: wrap;\n    gap: 20px;\n    & div:has(input) {\n        width: 100%;\n    \n}\n  }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
