// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_marginBottom36__hYL\\+Y{
    margin-bottom: 36px;
   
}
.dashboard_wonText__jBAvY{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #1A202C;
}
.dashboard_paddingLR__IC8IE{
    padding-left: 10px;
    padding-right: 10px;
   
}

@media (max-width: 991px) {

    .dashboard_paddingLR__IC8IE{
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
        margin-top: 10px;
    }

}`, "",{"version":3,"sources":["webpack://./src/app/pages/buyerdashboardApp/dashboard/dashboard.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;;AAEvB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,mBAAmB;;AAEvB;;AAEA;;IAEI;QACI,kBAAkB;QAClB,mBAAmB;QACnB,WAAW;QACX,gBAAgB;IACpB;;AAEJ","sourcesContent":[".marginBottom36{\n    margin-bottom: 36px;\n   \n}\n.wonText{\n    font-style: normal;\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 28px;\n    color: #1A202C;\n}\n.paddingLR{\n    padding-left: 10px;\n    padding-right: 10px;\n   \n}\n\n@media (max-width: 991px) {\n\n    .paddingLR{\n        padding-left: 10px;\n        padding-right: 10px;\n        width: 100%;\n        margin-top: 10px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginBottom36": `dashboard_marginBottom36__hYL+Y`,
	"wonText": `dashboard_wonText__jBAvY`,
	"paddingLR": `dashboard_paddingLR__IC8IE`
};
export default ___CSS_LOADER_EXPORT___;
