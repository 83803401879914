// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_enterOtpText__N19n- {
  color: #2d3748;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.styles_otpSentText__sZb62 {
  color: #718096;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}

.styles_otpText__kevUt {
  color: #2d3748;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 32px;
}

.styles_checkText__WOzrb {
  color: #4a5568;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.styles_resendCode__XcQSg {
  color: rgba(56, 161, 105, .3);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: not-allowed;
}

`, "",{"version":3,"sources":["webpack://./src/app/pages/home/RegistrationPage/OtpContents/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".enterOtpText {\n  color: #2d3748;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 28px;\n}\n\n.otpSentText {\n  color: #718096;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.otpText {\n  color: #2d3748;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  margin-top: 32px;\n}\n\n.checkText {\n  color: #4a5568;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.resendCode {\n  color: rgba(56, 161, 105, .3);\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  cursor: not-allowed;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"enterOtpText": `styles_enterOtpText__N19n-`,
	"otpSentText": `styles_otpSentText__sZb62`,
	"otpText": `styles_otpText__kevUt`,
	"checkText": `styles_checkText__WOzrb`,
	"resendCode": `styles_resendCode__XcQSg`
};
export default ___CSS_LOADER_EXPORT___;
