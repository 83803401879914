// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auctionCard_auctionTitle__sZXz\\+{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2D3748;
}
.auctionCard_auctionSubtitle__Sc8iT{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    align-items: center;

    /* gray/700 */

    color: #2D3748;
}
.auctionCard_card__\\+1KpZ{
    background: #FFFFFF;
    /* gray/200 */

    border: 1px solid #E2E8F0;
    /* sm */

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}
.auctionCard_cardBody__6S1zi{
    padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/shared/DCard/auctionCard.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,mBAAmB;;IAEnB,aAAa;;IAEb,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,aAAa;;IAEb,yBAAyB;IACzB,OAAO;;IAEP,2CAA2C;IAC3C,kBAAkB;AACtB;AACA;IACI,aAAa;AACjB","sourcesContent":[".auctionTitle{\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 20px;\n    color: #2D3748;\n}\n.auctionSubtitle{\n    font-style: normal;\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 32px;\n    align-items: center;\n\n    /* gray/700 */\n\n    color: #2D3748;\n}\n.card{\n    background: #FFFFFF;\n    /* gray/200 */\n\n    border: 1px solid #E2E8F0;\n    /* sm */\n\n    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n    border-radius: 4px;\n}\n.cardBody{\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auctionTitle": `auctionCard_auctionTitle__sZXz+`,
	"auctionSubtitle": `auctionCard_auctionSubtitle__Sc8iT`,
	"card": `auctionCard_card__+1KpZ`,
	"cardBody": `auctionCard_cardBody__6S1zi`
};
export default ___CSS_LOADER_EXPORT___;
