// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.liveAuction_cardTable__MZFyz{
    background-color: #fff;
    border: 1px solid #E2E8F0;
    border-radius: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.liveAuction_basic_addon__r6ixA{
    padding: 0.8rem 1rem;
    color: #000000;
    text-align: center;
    background: #EDF2F7;
    border: 1px solid #E2E8F0;
    border-radius: 4px 0px 0px 4px;
}
#liveAuction_search__Ja6r4::placeholder {
    color:#A0AEC0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/buyerdashboardApp/liveAuction/liveAuction.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,oBAAoB;IACpB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;IACnB,yBAAyB;IACzB,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".cardTable{\n    background-color: #fff;\n    border: 1px solid #E2E8F0;\n    border-radius: 12px;\n    padding-top: 10px;\n    padding-bottom: 10px;\n}\n\n.basic_addon{\n    padding: 0.8rem 1rem;\n    color: #000000;\n    text-align: center;\n    background: #EDF2F7;\n    border: 1px solid #E2E8F0;\n    border-radius: 4px 0px 0px 4px;\n}\n#search::placeholder {\n    color:#A0AEC0;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 19px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardTable": `liveAuction_cardTable__MZFyz`,
	"basic_addon": `liveAuction_basic_addon__r6ixA`,
	"search": `liveAuction_search__Ja6r4`
};
export default ___CSS_LOADER_EXPORT___;
