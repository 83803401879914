// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
 
}

body,
html {
  overflow-x: hidden;
  
}




.styles_agroTech__yToKr {
    padding: 48px 280px;
  }
  .styles_agroTech__yToKr h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  
    color: #2d3748;
  }
  
  .styles_agroTech__yToKr a {
    text-decoration: none;
    color: #38a169;
  }

  @media (max-width: 991px) {
    .styles_agroTech__yToKr {
  
      padding: 48px 20px;
      
    }
  }`, "",{"version":3,"sources":["webpack://./src/app/pages/home/agroTech/styles.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;;AAEZ;;AAEA;;EAEE,kBAAkB;;AAEpB;;;;;AAKA;IACI,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,eAAe;IACf,iBAAiB;;IAEjB,cAAc;EAChB;;EAEA;IACE,qBAAqB;IACrB,cAAc;EAChB;;EAEA;IACE;;MAEE,kBAAkB;;IAEpB;EACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n \n}\n\nbody,\nhtml {\n  overflow-x: hidden;\n  \n}\n\n\n\n\n.agroTech {\n    padding: 48px 280px;\n  }\n  .agroTech h1 {\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 32px;\n  \n    color: #2d3748;\n  }\n  \n  .agroTech a {\n    text-decoration: none;\n    color: #38a169;\n  }\n\n  @media (max-width: 991px) {\n    .agroTech {\n  \n      padding: 48px 20px;\n      \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"agroTech": `styles_agroTech__yToKr`
};
export default ___CSS_LOADER_EXPORT___;
