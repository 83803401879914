import React from "react";
import { Row, Col, Badge } from "react-bootstrap";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import featureImg from "../../../../../assets/dashboardImages/feature image.svg";
import otherImg1 from "../../../../../assets/dashboardImages/licensed-image 3.svg";
import otherImg2 from "../../../../../assets/dashboardImages/licensed-image 5.svg";
import otherImg3 from "../../../../../assets/dashboardImages/licensed-image 6.svg";

const Detail = () => {
    const features = [featureImg, otherImg1, otherImg2, otherImg3];
    const [firstImage, ...restImages] = features;

    return (
        <>
            <Row>
                <Col lg={6}><span className={styles.heading}>Auction details</span></Col>
                <Col lg={5} style={{ marginLeft: '1.2em', }}><span className={styles.heading}>Images</span></Col>                
            </Row>
            <Row>
                <Col lg={6} style={{marginTop: '1em', marginLeft:'0.8em', marginRight: '0.8em', paddingLeft: '0.2em'}} className={[styles.colStyle]}>
                    <Row className={styles.rowStyle}>
                        <Col lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.pheadingtext}>Auction ID</span></Col><Col lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.valueText}>#SJ23423</span></Col>
                        <Col lg={6}  className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.pheadingtext}>Auction launch date</span></Col><Col  lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.valueText}>25 May 2023</span></Col>
                        <Col lg={6}  className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.pheadingtext}>Herb name</span></Col><Col  lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.valueText}>Rosemary</span></Col>
                        <Col lg={6}  className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.pheadingtext}>Variety</span></Col><Col  lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.valueText}>₹300</span></Col>
                        <Col lg={6}  className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.pheadingtext}>Total quantity</span></Col><Col  lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.valueText}>₹300</span></Col>
                        <Col lg={6}  className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.pheadingtext}>Ask price / kg</span></Col><Col  lg={6} className={[styles.paddingCol, styles.borderbtm].join(' ')}><span className={styles.valueText}>₹300</span></Col>
                        <Col lg={6}  className={[styles.paddingCol].join(' ')}><span className={styles.pheadingtext}>Bid increment / kg</span></Col><Col  lg={6} className={[styles.paddingCol].join(' ')}><span className={styles.valueText}>₹100</span></Col>
                    </Row>
                </Col>
                <Col lg={5} style={{marginTop: '1em', marginLeft:'0.5em', marginRight: '0.5em'}} className={styles.colStyle}>
                    <Row className={styles.imageRow}>
                        <Col lg={12}><img src={firstImage} alt="Feature Image"  className={[styles.featuredImage, 'img-fluid'].join(' ')} /></Col>

                        {restImages.map((image, index) => (
                            <Col lg={4} >
                                <img key={index} src={image} className={[styles.nImage, 'img-fluid', 'rounded'].join(' ')} alt={`Image ${index + 2}`} />
                            </Col>                            
                        ))}
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: '1em'}}>
                <Col lg={6}><span className={styles.heading}>Description</span></Col>              
            </Row>
            <Row>
                <Col lg={12} style={{marginTop: '1em', marginLeft:'0.5em', marginRight: '0.5em'}} className={[styles.colStyle]}>
                    <Row className={styles.rowStyle}>
                        <Col lg={12} className={[styles.paddingCol].join(' ')}><span className={styles.bheadingtext}>Grade description</span></Col>
                        <Col lg={12} className={[styles.paddingCol].join(' ')}><span className={styles.valueText}>Ayurvedic remedies and modern formulas, Pushkarmool has been used for everything from heart health to lung function. Promotes normal breathing and clear airways, while helping to remove excess kapha (water) from the respiratory tract. A rejuvenating tonic, this plant nourishes lung tissue and protects lung health. Supports clear arteries, blood flow and healthy blood pressure, which helps protect and preserve heart health.</span></Col>
                        <Col lg={12} className={[styles.paddingCol].join(' ')}><span className={styles.bheadingtext}>Method of collection</span></Col>
                        <Row>
                            <Col lg={12} className={[styles.paddingCol].join(' ')}><Badge className={[styles.nheadingText, styles.badgeBg].join(' ')}>Cultivated</Badge></Col>
                            <Col lg={10} className={[styles.paddingCol].join(' ')}>
                                <Row>
                                    <Col lg={6} className={styles.valueText}>Report of patwari / revenue authority / godavari</Col><Col lg={6} className={styles.valueText}>Additional document</Col>
                                    <Col lg={6} ><Link to="/" className={styles.aText}>report.pdf</Link></Col><Col lg={6} ><Link to="/" className={styles.aText}>report.pdf</Link></Col>
                                </Row>
                            </Col>
                            <Col lg={12} className={[styles.paddingCol].join(' ')}>
                                <span className={styles.nheadingText}>Land details:</span>
                            </Col>
                            <Col lg={10} className={[styles.paddingCol].join(' ')}>
                                <Row>
                                    <Col lg={3} className={styles.valueText}>Village name</Col><Col lg={3} className={styles.valueText}>Tehsil name</Col><Col lg={3} className={styles.valueText}>State</Col><Col lg={3} className={styles.valueText}>District</Col>
                                    <Col lg={3} className={styles.nheadingText}>Village name</Col><Col lg={3} className={styles.nheadingText}>Tehsil name</Col><Col lg={3} className={styles.nheadingText}>State</Col><Col lg={3} className={styles.nheadingText}>District</Col>
                                </Row>
                            </Col>
                            <Col lg={12} className={[styles.paddingCol].join(' ')}>
                                <Row>
                                    <Col lg={12} className={styles.valueText}>Legal procurement certificate (LPC)</Col>
                                    <Col lg={3}><Link to="/" className={styles.aText}>report.pdf</Link></Col>
                                </Row>
                            </Col>
                            <Col lg={12} className={[styles.paddingCol].join(' ')}>
                                <Row>
                                    <Col lg={12} className={styles.nheadingText}>Packaging</Col>
                                    <Col lg={2} className={styles.valueText}><span>Type:</span> <span>ABC type</span></Col><Col lg={2} className={styles.valueText}><span>Size:</span><span>4kg</span></Col>
                                </Row>
                            </Col>
                        </Row>
                        
                    </Row>
                </Col>
            </Row>
        </>
    )
}
export default Detail;