// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_accountDetails__E2R7A {
  color: #2d3748;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/home/login/styles.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".accountDetails {\n  color: #2d3748;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 28px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountDetails": `styles_accountDetails__E2R7A`
};
export default ___CSS_LOADER_EXPORT___;
