// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_heading__BOS8P{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
}
.style_rowStyle__Eeyve{
    margin-left: 0px;
    padding: 1em;
}
.style_pheadingtext__pniid {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2D3748;
}
.style_colStyle__QI9cW{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    width: 48.35%;
    padding: 0px;
}
.style_valueText__ctq2a{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2D3748;
}
.style_paddingCol__4aC\\+S{
    padding: 1em 1em 1em 1.5em;
}

.style_borderbtm__Pu3uV{
    border-bottom: 1px solid #E2E8F0;
}
.style_imgStyle__hqiqv{
    width: 70px;
    height: 43px;
    left: 24px;
    top: 15.33px;
    border-radius: 8px;
}

@media (max-width: 991px) {
    .style_colStyle__QI9cW{
        background: #FFFFFF;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        width: 100%;
        padding: 0px;
    }
    }`, "",{"version":3,"sources":["webpack://./src/app/components/dashboardApp/GeneralDetail/style.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,2CAA2C;IAC3C,kBAAkB;IAClB,aAAa;IACb,YAAY;AAChB;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gCAAgC;AACpC;AACA;IACI,WAAW;IACX,YAAY;IACZ,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI;QACI,mBAAmB;QACnB,2CAA2C;QAC3C,kBAAkB;QAClB,WAAW;QACX,YAAY;IAChB;IACA","sourcesContent":[".heading{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 28px;\n    color: #000000;\n}\n.rowStyle{\n    margin-left: 0px;\n    padding: 1em;\n}\n.pheadingtext {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 20px;\n    color: #2D3748;\n}\n.colStyle{\n    background: #FFFFFF;\n    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n    border-radius: 8px;\n    width: 48.35%;\n    padding: 0px;\n}\n.valueText{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: #2D3748;\n}\n.paddingCol{\n    padding: 1em 1em 1em 1.5em;\n}\n\n.borderbtm{\n    border-bottom: 1px solid #E2E8F0;\n}\n.imgStyle{\n    width: 70px;\n    height: 43px;\n    left: 24px;\n    top: 15.33px;\n    border-radius: 8px;\n}\n\n@media (max-width: 991px) {\n    .colStyle{\n        background: #FFFFFF;\n        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n        border-radius: 8px;\n        width: 100%;\n        padding: 0px;\n    }\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `style_heading__BOS8P`,
	"rowStyle": `style_rowStyle__Eeyve`,
	"pheadingtext": `style_pheadingtext__pniid`,
	"colStyle": `style_colStyle__QI9cW`,
	"valueText": `style_valueText__ctq2a`,
	"paddingCol": `style_paddingCol__4aC+S`,
	"borderbtm": `style_borderbtm__Pu3uV`,
	"imgStyle": `style_imgStyle__hqiqv`
};
export default ___CSS_LOADER_EXPORT___;
