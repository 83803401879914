// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_lightText__o4syc {
  color: #0000007a;
  font-size: 14px;
  /* line-height: 20px; */
  margin-left: 14px;
  margin-right: 5px;
}

.styles_darkText__DUfmd {
  color: #000000eb;
  font-weight: 600;
  font-size: 14px;
  /* line-height: 20px; */
}
.styles_hederaBar__kLYNZ {
  width: 100%;
  height: 40px;
  background-color: #f7fafc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_textContainer__SQI2O {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/homeApp/hederaBar/styles.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,eAAe;EACf,uBAAuB;EACvB,iBAAiB;EACjB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,eAAe;EACf,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".lightText {\n  color: #0000007a;\n  font-size: 14px;\n  /* line-height: 20px; */\n  margin-left: 14px;\n  margin-right: 5px;\n}\n\n.darkText {\n  color: #000000eb;\n  font-weight: 600;\n  font-size: 14px;\n  /* line-height: 20px; */\n}\n.hederaBar {\n  width: 100%;\n  height: 40px;\n  background-color: #f7fafc;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.textContainer {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lightText": `styles_lightText__o4syc`,
	"darkText": `styles_darkText__DUfmd`,
	"hederaBar": `styles_hederaBar__kLYNZ`,
	"textContainer": `styles_textContainer__SQI2O`
};
export default ___CSS_LOADER_EXPORT___;
