// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_paymentForm__NZHae {
  width: 100%;
  padding: 28px;
  background-color: #fff;
  border-radius: 8px;
  margin: 32px auto 40px;
}

.styles_paymentForm__NZHae h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
}

.styles_formContainer__bsbUm {
  width: 100%;
  display: flex;
  align-items: stretch;
  margin-bottom: 28px;
}

.styles_formLeft__P5Y8v {
  width: 50%;
  padding-right: 28px;
}

.styles_formRight__OSCrC {
  width: 50%;
  padding-left: 28px;
}

.styles_inputContainer__aLFQo {
  width: 100%;
  margin: 16px auto;
}

.styles_inputContainer__aLFQo label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2d3748;
}

.styles_inputContainer__aLFQo input {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #222;
  outline: none;
}

.styles_inputContainer__aLFQo input::placeholder {
  color: #ccc;
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/buyerdashboardApp/auctionDetail/payment/paymentForm/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,oBAAoB;EACpB,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;EACX,UAAU;AACZ","sourcesContent":[".paymentForm {\n  width: 100%;\n  padding: 28px;\n  background-color: #fff;\n  border-radius: 8px;\n  margin: 32px auto 40px;\n}\n\n.paymentForm h3 {\n  font-weight: 600;\n  font-size: 18px;\n  line-height: 28px;\n  color: #000000;\n}\n\n.formContainer {\n  width: 100%;\n  display: flex;\n  align-items: stretch;\n  margin-bottom: 28px;\n}\n\n.formLeft {\n  width: 50%;\n  padding-right: 28px;\n}\n\n.formRight {\n  width: 50%;\n  padding-left: 28px;\n}\n\n.inputContainer {\n  width: 100%;\n  margin: 16px auto;\n}\n\n.inputContainer label {\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #2d3748;\n}\n\n.inputContainer input {\n  width: 100%;\n  padding: 10px 16px;\n  border: 1px solid #e2e8f0;\n  border-radius: 6px;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 19px;\n  color: #222;\n  outline: none;\n}\n\n.inputContainer input::placeholder {\n  color: #ccc;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paymentForm": `styles_paymentForm__NZHae`,
	"formContainer": `styles_formContainer__bsbUm`,
	"formLeft": `styles_formLeft__P5Y8v`,
	"formRight": `styles_formRight__OSCrC`,
	"inputContainer": `styles_inputContainer__aLFQo`
};
export default ___CSS_LOADER_EXPORT___;
