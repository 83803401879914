import { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/auth";
import OtpComponent from "../../auth/login/OtpContents";
import styles from "./styles.module.css";

const HomeLogin = () => {
  const auth = useAuth();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = () => {
    auth.login({ username, password });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "500px",
        }}
      >
        <h3>Email</h3>
        <input
          type="text"
          name="username"
          label="username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <h3>Password</h3>
        <input
          type="text"
          name="password"
          label="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <button style={{ marginTop: "10px" }} onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </>
  );
};

export default HomeLogin;
