import React from "react";
import { Form } from 'react-bootstrap';

const DateInput= () => {
    return (
        <Form.Group controlId="dob">
            <Form.Label>Select Date</Form.Label>
            <Form.Control type="date" name="dob" placeholder="Date of Birth" />
        </Form.Group>
    );
};
export default DateInput;