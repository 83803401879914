// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_marginBottom36__G4ppy{
    margin-bottom: 36px;
}
.dashboard_wonText__f31OT{
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #1A202C;
}
.dashboard_paddingLR__UU9lY{
    padding-left: 10px;
    padding-right: 10px;
}
.dashboard_colcustom3__NXAsW{
    width: 20% !important;
}

@media (max-width: 991px) {
    .dashboard_colcustom3__NXAsW{
        width: 100% !important;
        margin-top: 15px;
    }


}`, "",{"version":3,"sources":["webpack://./src/app/pages/sellerdashboardApp/dashboard/dashboard.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;AACA;IACI,qBAAqB;AACzB;;AAEA;IACI;QACI,sBAAsB;QACtB,gBAAgB;IACpB;;;AAGJ","sourcesContent":[".marginBottom36{\n    margin-bottom: 36px;\n}\n.wonText{\n    font-style: normal;\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 28px;\n    color: #1A202C;\n}\n.paddingLR{\n    padding-left: 10px;\n    padding-right: 10px;\n}\n.colcustom3{\n    width: 20% !important;\n}\n\n@media (max-width: 991px) {\n    .colcustom3{\n        width: 100% !important;\n        margin-top: 15px;\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"marginBottom36": `dashboard_marginBottom36__G4ppy`,
	"wonText": `dashboard_wonText__f31OT`,
	"paddingLR": `dashboard_paddingLR__UU9lY`,
	"colcustom3": `dashboard_colcustom3__NXAsW`
};
export default ___CSS_LOADER_EXPORT___;
