// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_rowStyle__GVuRY {
  padding: 0.5em;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-top: 1em;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/dashboardApp/ProfileDetail/style.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,sBAAsB;EACtB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".rowStyle {\n  padding: 0.5em;\n  background-color: #fff;\n  border: 1px solid #e2e8f0;\n  border-radius: 8px;\n  margin-top: 1em;\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowStyle": `style_rowStyle__GVuRY`
};
export default ___CSS_LOADER_EXPORT___;
