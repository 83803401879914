// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_formheadingText__4DsU6{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #2D3748;
}
.style_dropzone__JDcp2 {
    border: 2px dashed #E2E8F0;
    border-radius: 5px;
    padding: 5rem;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
    height: 30vh;
  }
  
  .style_previewContainer__AzY3a {
    display: flex;
    flex-wrap: wrap;
  }
  
  .style_preview__DSYZt {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .style_preview__DSYZt img {
    width: 180px;
    height: auto;
  }
  
  .style_preview__DSYZt button {
    margin-top: 10px;
  }
  
  .style_rowStyle__wV6WQ{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 0.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/sellerdashboardApp/createAuction/images/style.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,YAAY;EACd;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,mBAAmB;IACnB,2CAA2C;IAC3C,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".formheadingText{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 28px;\n    color: #2D3748;\n}\n.dropzone {\n    border: 2px dashed #E2E8F0;\n    border-radius: 5px;\n    padding: 5rem;\n    text-align: center;\n    cursor: pointer;\n    margin-bottom: 20px;\n    height: 30vh;\n  }\n  \n  .previewContainer {\n    display: flex;\n    flex-wrap: wrap;\n  }\n  \n  .preview {\n    margin-right: 10px;\n    margin-bottom: 10px;\n  }\n  \n  .preview img {\n    width: 180px;\n    height: auto;\n  }\n  \n  .preview button {\n    margin-top: 10px;\n  }\n  \n  .rowStyle{\n    background: #FFFFFF;\n    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n    border-radius: 8px;\n    padding: 0.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formheadingText": `style_formheadingText__4DsU6`,
	"dropzone": `style_dropzone__JDcp2`,
	"previewContainer": `style_previewContainer__AzY3a`,
	"preview": `style_preview__DSYZt`,
	"rowStyle": `style_rowStyle__wV6WQ`
};
export default ___CSS_LOADER_EXPORT___;
