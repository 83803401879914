// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_auctionID__q1vdB{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #4A5568;
}
.style_visitBtn__J7jZu{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    color: #1A202C;
    padding: 5px 8px;
    border: 1px solid #E2E8F0;
    border-radius: 6px;
    text-decoration: none;
    margin-right: 4px;
    width: 46%;
    text-align: center;
}
.style_tabDiv__DXD9q{
    border-width: 1.5px 0px;
    border-style: solid;
    border-color: #E2E8F0;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/sellerdashboardApp/auctionDetail/style.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,qBAAqB;IACrB,iBAAiB;IACjB,UAAU;IACV,kBAAkB;AACtB;AACA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;AACzB","sourcesContent":[".auctionID{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: #4A5568;\n}\n.visitBtn{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 12px;\n    line-height: 24px;\n    color: #1A202C;\n    padding: 5px 8px;\n    border: 1px solid #E2E8F0;\n    border-radius: 6px;\n    text-decoration: none;\n    margin-right: 4px;\n    width: 46%;\n    text-align: center;\n}\n.tabDiv{\n    border-width: 1.5px 0px;\n    border-style: solid;\n    border-color: #E2E8F0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auctionID": `style_auctionID__q1vdB`,
	"visitBtn": `style_visitBtn__J7jZu`,
	"tabDiv": `style_tabDiv__DXD9q`
};
export default ___CSS_LOADER_EXPORT___;
