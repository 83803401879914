import React, { useEffect, useState, useRef } from "react";
// import "./styles.css";
import successIcon from "../../../../../assets/icons/success.svg";
import uploadIcon from "../../../../../assets/icons/upload-icon.svg";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Divider from "@mui/material/Divider";
import { toast } from "react-toastify";
import { withStyles } from "@mui/styles";

const containerStyle = {
  width: "661px",
  border: "1px solid #E2E8F0",
  borderRadius: "12px",
  backgroundColor: "#FFFFFF",
  paddingLeft: "36px",
  paddingRight: "36px",
  paddingBottom: "27px",
  paddingTop: "27px",
};
const detailsContainer = {
  width: "661px",
  border: "1px solid #E2E8F0",
  borderRadius: "6px",
  padding: "28px",
};
const inputStyles = {
  height: "16px",
  width: "251px",
  borderRadius: "6px",
};
const verifyButtonStyles = {
  root: {
    width: "605px",
    height: "48px",
    borderRadius: "6px",
    backgroundColor: "#38A169 !important",
  },
};

export default function RegistrationSuccessfulPage(props) {
  const [transactionId, setTransactionid] = useState("");
  const [file, setFile] = useState(null);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const CustomVerifyButton = withStyles(verifyButtonStyles)(
    ({ classes, ...props }) => <Button className={classes.root} {...props} />
  );

  const handlePaymentDetailSubmit = () => {
    if (!transactionId || !file || !fullName || !email || !phone) {
      toast.warning("kindly, Fill all the details", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    toast.success("Thank You for the submission of payment details", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    props.onClickApproveButton();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <div>
            <img src={successIcon} alt="successIcon" />
          </div>
          <div
            style={{
              color: "#000000",
              fontWeight: 500,
              fontSize: "24px",
              lineHeight: "32px",
              marginTop: "32px",
            }}
          >
            Registration Successful!
          </div>
          <div
            style={{
              color: "#2D3748",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "36px",
            }}
          >
            Thank you for registering with us!
          </div>
          <div
            style={{
              display: "flex",
              gap: 4,
              marginTop: "44px",
              alignItems: "CENTER",
            }}
          >
            {/* <span
              style={{
                borderRadius: "4px",
                background: "#38A169",
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "16px",
                color: "#FFFFFF",
                padding: "3px 6px",
              }}
            >
              STEP1
            </span> */}
            <div
              style={{
                color: "#2D3748",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              To start using the benefits of the platform, please make a{" "}
              <span style={{ fontWeight: "600", color: " #2D3748" }}>
                registration fee of Rs 50,00
              </span>{" "}
              via NEFT. You can find account details here:
            </div>
          </div>
          <div style={{ marginTop: "24px" }}>
            <Box style={containerStyle}>
              <div style={{ display: "flex", gap: 200 }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  Beneficiary's name
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Agnext Technologies
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <Divider />
              </div>
              <div style={{ display: "flex", gap: 128, marginTop: "15px" }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  Beneficiary's account number
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  1234567890
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <Divider />
              </div>
              <div style={{ display: "flex", gap: 162, marginTop: "15px" }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  Beneficiary's bank name
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  State Bank of India
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <Divider />
              </div>
              <div style={{ display: "flex", gap: 113, marginTop: "15px" }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  Beneficiary's bank branch name
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  Main Branch
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <Divider />
              </div>
              <div style={{ display: "flex", gap: 166, marginTop: "15px" }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 500,
                    lineHeight: "20px",
                  }}
                >
                  Beneficiary's IFSC code
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#2D3748",
                    fontWeight: 400,
                    lineHeight: "20px",
                  }}
                >
                  SBIN0000001
                </div>
              </div>
            </Box>
          </div>
          {/* <div
            style={{
              display: "flex",
              gap: 4,
              marginTop: "44px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                borderRadius: "4px",
                background: "#38A169",
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "16px",
                color: "#FFFFFF",
                padding: "3px 6px",
              }}
            >
              STEP2
            </div>
            <div
              style={{
                color: "#2D3748",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "28px",
              }}
            >
              Once you have made your payment, please fill the following form:
            </div>
          </div> */}
          {/* <div style={{ marginTop: "16px" }}>
            <Box style={detailsContainer}>
              <div style={{ display: "flex", gap: 50, marginTop: "44px" }}>
                <div>
                  <div
                    style={{
                      color: "#2D3748",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Transaction ID or UTR number
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      placeholder="Ex. ABC Bank"
                      inputProps={{
                        style: inputStyles,
                      }}
                      value={transactionId}
                      onChange={(e) => setTransactionid(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: "#2D3748",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Screenshot of the receipt
                  </div>
                  <div
                    style={{
                      marginTop: "8px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      type="file"
                      onChange={(e) => {
                        setFile(e.target.value[0]);
                        console.log("great success");
                      }}
                      inputProps={{
                        accept: "image",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", gap: 50, marginTop: "20px" }}>
                <div>
                  <div
                    style={{
                      color: "#2D3748",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Your full name
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      placeholder="Full Name"
                      inputProps={{
                        style: inputStyles,
                      }}
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      color: "#2D3748",
                      fontWeight: 400,
                      fontSize: "16px",
                      lineHeight: "24px",
                    }}
                  >
                    Registered email address
                  </div>
                  <div style={{ marginTop: "8px" }}>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      aria-describedby="outlined-weight-helper-text"
                      type="email"
                      placeholder="Email"
                      inputProps={{
                        style: inputStyles,
                      }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "20px" }}>
                <div
                  style={{
                    color: "#2D3748",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  Registered phone number
                </div>
                <div style={{ marginTop: "8px" }}>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    placeholder="Ex. 7736XXXXXX"
                    inputProps={{
                      style: inputStyles,
                    }}
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </div>
              </div>
              <div style={{ marginTop: "40px" }}>
                <CustomVerifyButton onClick={handlePaymentDetailSubmit}>
                  <div
                    style={{
                      color: "#FFFFFF",
                      fontWeight: "600",
                      fontSize: "18px",
                      lineHeight: "28px",
                    }}
                  >
                    Submit Payment Proof
                  </div>
                </CustomVerifyButton>
              </div>
            </Box>
          </div> */}
          <div
            style={{
              display: "flex",
              gap: 4,
              marginTop: "44px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                borderRadius: "4px",
                background: "#38A169",
                fontSize: "12px",
                fontWeight: 700,
                lineHeight: "16px",
                color: "#FFFFFF",
                padding: "3px 6px",
              }}
            >
              FINAL STEP
            </div>
            <div
              style={{
                color: "#2D3748",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "28px",
              }}
            >
              We will verify your details and approve your account within 48
              hours of receiving your payment.
            </div>
          </div>
          <div
            style={{
              color: "#718096",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "36px",
              marginTop: "56px",
            }}
          >
            If you have any questions, please do not hesitate to contact us. You
            can reach us by email at{" "}
            <span style={{ color: "#38A169" }}>contact@agnext.in</span> or by
            phone at
            <span style={{ color: "#38A169" }}>+91-988-XXX-XXX</span>.
          </div>
          <div
            style={{
              color: "#718096",
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "36px",
              marginTop: "56px",
            }}
          >
            Team Herb Connect
          </div>
        </div>
      </div>
    </>
  );
}
