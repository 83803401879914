// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
 
}

body,
html {
  overflow-x: hidden;
  
}





.styles_login__vp41I {
  padding: 48px;
}

.styles_login__vp41I h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2d3748;
  text-align: center;
}

.styles_login__vp41I h2 {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #2d3748;
}

.styles_formContainer__G\\+Yqx {
  padding: 28px;
  width: 450px;
  margin: 48px auto;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.styles_formContainer__G\\+Yqx div {
  margin: 32px auto 32px 0;
}

.styles_formContainer__G\\+Yqx label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #2d3748;
  padding-bottom: 8px;
}

.styles_formContainer__G\\+Yqx input {
  width: 100%;
  padding: 12px 16px;
  background-color: none;
  outline: none;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
}

@media (max-width: 991px) {

  .styles_login__vp41I {
    padding: 50px;
    display: flex;
}

.styles_login__vp41I h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #2d3748;
  text-align: center;
  position: absolute;
}
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/auth/login/styles.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;;AAEZ;;AAEA;;EAEE,kBAAkB;;AAEpB;;;;;;AAMA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,2EAA2E;EAC3E,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,sBAAsB;EACtB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;;EAEE;IACE,aAAa;IACb,aAAa;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,kBAAkB;AACpB;AACA","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n \n}\n\nbody,\nhtml {\n  overflow-x: hidden;\n  \n}\n\n\n\n\n\n.login {\n  padding: 48px;\n}\n\n.login h1 {\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 32px;\n  color: #2d3748;\n  text-align: center;\n}\n\n.login h2 {\n  font-weight: 500;\n  font-size: 18px;\n  line-height: 28px;\n  color: #2d3748;\n}\n\n.formContainer {\n  padding: 28px;\n  width: 450px;\n  margin: 48px auto;\n  background: #ffffff;\n  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);\n  border-radius: 8px;\n}\n\n.formContainer div {\n  margin: 32px auto 32px 0;\n}\n\n.formContainer label {\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  color: #2d3748;\n  padding-bottom: 8px;\n}\n\n.formContainer input {\n  width: 100%;\n  padding: 12px 16px;\n  background-color: none;\n  outline: none;\n  border: 1px solid #e2e8f0;\n  border-radius: 6px;\n}\n\n@media (max-width: 991px) {\n\n  .login {\n    padding: 50px;\n    display: flex;\n}\n\n.login h1 {\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 32px;\n  color: #2d3748;\n  text-align: center;\n  position: absolute;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `styles_login__vp41I`,
	"formContainer": `styles_formContainer__G+Yqx`
};
export default ___CSS_LOADER_EXPORT___;
