// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
 
}

body,
html {
  overflow-x: hidden;
  
}


.styles_auctionDetails__woiG8 {
  padding: 36px 64px;
}

.styles_links__bcRwN {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 40px;
}

.styles_links__bcRwN img {
  height: 12px;
}

.styles_links__bcRwN a {
  text-decoration: none;
  color: #2d3748;
}

.styles_links__bcRwN a:hover {
  color: #38a169;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/home/auctionDetails/styles.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;;AAEZ;;AAEA;;EAEE,kBAAkB;;AAEpB;;;AAGA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n \n}\n\nbody,\nhtml {\n  overflow-x: hidden;\n  \n}\n\n\n.auctionDetails {\n  padding: 36px 64px;\n}\n\n.links {\n  display: flex;\n  gap: 12px;\n  align-items: center;\n  margin-bottom: 40px;\n}\n\n.links img {\n  height: 12px;\n}\n\n.links a {\n  text-decoration: none;\n  color: #2d3748;\n}\n\n.links a:hover {\n  color: #38a169;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auctionDetails": `styles_auctionDetails__woiG8`,
	"links": `styles_links__bcRwN`
};
export default ___CSS_LOADER_EXPORT___;
