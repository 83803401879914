// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_formheadingText__yrQKj{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #2D3748;
}
.style_rowStyle__Q-81A{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    padding: 0.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/sellerdashboardApp/createAuction/quality/style.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,2CAA2C;IAC3C,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".formheadingText{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 20px;\n    line-height: 28px;\n    color: #2D3748;\n}\n.rowStyle{\n    background: #FFFFFF;\n    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n    border-radius: 8px;\n    padding: 0.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formheadingText": `style_formheadingText__yrQKj`,
	"rowStyle": `style_rowStyle__Q-81A`
};
export default ___CSS_LOADER_EXPORT___;
