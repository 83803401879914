// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_rowStyle__RiyaM{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-top: 1em;
    margin-left: 0px;
    padding: 1em;
}
.style_paddingCol__FQ46X{
    padding: 1em 1em 1em 1.5em;
}
.style_headingText__hpPAx{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2D3748;
}
.style_valueText__ZRDe2{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2D3748;
}
.style_lightText__ROO8X {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.48);
    margin-left: 14px;
    margin-right: 5px;
    margin-top: 1em;
  }
  
  .style_darkText__kQ0SF {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.92);
    margin-top: 1em;
  }`, "",{"version":3,"sources":["webpack://./src/app/pages/admindashboardApp/auctionDetail/traceability/style.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,2CAA2C;IAC3C,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,0BAA0B;IAC1B,iBAAiB;IACjB,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,0BAA0B;IAC1B,eAAe;EACjB","sourcesContent":[".rowStyle{\n    background: #FFFFFF;\n    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n    border-radius: 8px;\n    margin-top: 1em;\n    margin-left: 0px;\n    padding: 1em;\n}\n.paddingCol{\n    padding: 1em 1em 1em 1.5em;\n}\n.headingText{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 20px;\n    color: #2D3748;\n}\n.valueText{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: #2D3748;\n}\n.lightText {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: rgba(0, 0, 0, 0.48);\n    margin-left: 14px;\n    margin-right: 5px;\n    margin-top: 1em;\n  }\n  \n  .darkText {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 20px;\n    color: rgba(0, 0, 0, 0.92);\n    margin-top: 1em;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rowStyle": `style_rowStyle__RiyaM`,
	"paddingCol": `style_paddingCol__FQ46X`,
	"headingText": `style_headingText__hpPAx`,
	"valueText": `style_valueText__ZRDe2`,
	"lightText": `style_lightText__ROO8X`,
	"darkText": `style_darkText__kQ0SF`
};
export default ___CSS_LOADER_EXPORT___;
