// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_myBids__T7\\+uf {
  width: 280px;
  padding: 12px;
}

.styles_myBids__T7\\+uf div {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(226, 232, 240, 1);
}

.styles_myBids__T7\\+uf div:last-child {
  border: none;
}

.styles_myBids__T7\\+uf span {
  width: 50%;
  padding: 20px 24px;
  font-weight: 500;
  line-height: 20px;
  color: #2d3748;
}

.styles_heading__R5NkY span {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #4a5568;
}


`, "",{"version":3,"sources":["webpack://./src/app/components/homeApp/myBids/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,+CAA+C;AACjD;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,UAAU;EACV,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".myBids {\n  width: 280px;\n  padding: 12px;\n}\n\n.myBids div {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid rgba(226, 232, 240, 1);\n}\n\n.myBids div:last-child {\n  border: none;\n}\n\n.myBids span {\n  width: 50%;\n  padding: 20px 24px;\n  font-weight: 500;\n  line-height: 20px;\n  color: #2d3748;\n}\n\n.heading span {\n  font-size: 14px;\n  font-weight: 700;\n  line-height: 16px;\n  color: #4a5568;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"myBids": `styles_myBids__T7+uf`,
	"heading": `styles_heading__R5NkY`
};
export default ___CSS_LOADER_EXPORT___;
