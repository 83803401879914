// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_backdrop__8PLYq {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, .4);
    z-index: 100;
    
}

.styles_modal__1RANB {
    padding: 44px;
    background-color: #fff;
    width: 1100px;
    border-radius: 16px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



.styles_close__5FUPs {
    position: absolute;
    top: 16px;
    right: 24px;
    cursor: pointer;
}


@media (max-width: 991px) {


    .styles_modal__1RANB {
        padding: 44px;
        background-color: #fff;
        width: 346px;
        border-radius: 16px;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 746px;
    }

   


}
`, "",{"version":3,"sources":["webpack://./src/app/components/homeApp/modal/styles.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,mCAAmC;IACnC,YAAY;;AAEhB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;AACpC;;;;AAIA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;AACnB;;;AAGA;;;IAGI;QACI,aAAa;QACb,sBAAsB;QACtB,YAAY;QACZ,mBAAmB;QACnB,kBAAkB;QAClB,QAAQ;QACR,SAAS;QACT,gCAAgC;QAChC,aAAa;IACjB;;;;;AAKJ","sourcesContent":[".backdrop {\n    width: 100vw;\n    height: 100vh;\n    position: fixed;\n    top: 0;\n    left: 0;\n    background-color: rgba(0, 0, 0, .4);\n    z-index: 100;\n    \n}\n\n.modal {\n    padding: 44px;\n    background-color: #fff;\n    width: 1100px;\n    border-radius: 16px;\n    position: relative;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n}\n\n\n\n.close {\n    position: absolute;\n    top: 16px;\n    right: 24px;\n    cursor: pointer;\n}\n\n\n@media (max-width: 991px) {\n\n\n    .modal {\n        padding: 44px;\n        background-color: #fff;\n        width: 346px;\n        border-radius: 16px;\n        position: relative;\n        top: 50%;\n        left: 50%;\n        transform: translate(-50%, -50%);\n        height: 746px;\n    }\n\n   \n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `styles_backdrop__8PLYq`,
	"modal": `styles_modal__1RANB`,
	"close": `styles_close__5FUPs`
};
export default ___CSS_LOADER_EXPORT___;
