import React, { useState } from "react";
import styles from "./styles.module.css";
import Button from "../../../../../components/shared/button";

const buttonStyle = {
  width: "100%",
  fontWeight: "600",
  fontSize: "18px",
  padding: "14px",
};

export default function PaymentForm() {
  const [herb, setHerb] = useState("");
  const [auctionId, setAuctionId] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [paymentReceipt, setPaymentReceipt] = useState(null);
  const [contactPerson, setContactPerson] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");
  const [pin, setPin] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !herb ||
      !auctionId ||
      !email ||
      !phone ||
      !transactionId ||
      !paymentReceipt ||
      !contactPerson ||
      !mobile ||
      !address ||
      !state ||
      !district ||
      !pin
    ) {
      alert("Please, Fill in all the payment and delivery details");
      return;
    }
    alert("Thank you, payment and delivery details submitted successfully");
  };

  return (
    <div className={styles.paymentForm}>
      <form onSubmit={handleSubmit}>
        <div className={styles.formContainer}>
          <div className={styles.formLeft}>
            <h3>Payment Proof</h3>
            <div className={styles.inputContainer}>
              <label htmlFor="herb">Herb Name</label>
              <input
                type="text"
                name="herb"
                placeholder="Ex. Mint"
                value={herb}
                onChange={(e) => setHerb(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="auctionId">Auction ID</label>
              <input
                type="text"
                name="auctionId"
                placeholder="Ex. 1234XXXXXX"
                value={auctionId}
                onChange={(e) => setAuctionId(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="email">Registered email address</label>
              <input
                type="email"
                name="email"
                placeholder="Ex. abcxxx@abc.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="phone">Registered phone number</label>
              <input
                type="text"
                name="phone"
                placeholder="Ex. 9834XXXXXX"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="transactionId">
                Transaction ID or UTR number
              </label>
              <input
                type="text"
                name="transactionId"
                placeholder="Ex. ABC352XXXXXX"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="screenshot">Herb Name</label>
              <input
                type="file"
                accept="image"
                name="screenshot"
                placeholder="Upload"
                onChange={(e) => setPaymentReceipt(e.target.value[0])}
              />
            </div>
          </div>
          <div className={styles.formRight}>
            <h3>Add delivery address</h3>
            <div className={styles.inputContainer}>
              <label htmlFor="poc">Contact person name</label>
              <input
                type="text"
                name="poc"
                placeholder="Ex. Sanjay Srivastava"
                value={contactPerson}
                onChange={(e) => setContactPerson(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="mobile">Mobile Number</label>
              <input
                type="text"
                name="mobile"
                placeholder="Ex. 7736XXXXXX"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="address">Full address</label>
              <input
                type="text"
                name="address"
                placeholder="Ex. abc street ..."
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="state">State</label>
              <input
                type="text"
                name="state"
                placeholder="Ex. Haryana"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="district">District</label>
              <input
                type="text"
                name="district"
                placeholder="Ex. Jind"
                value={district}
                onChange={(e) => setDistrict(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="pin">Pin Code</label>
              <input
                type="text"
                name="pin"
                placeholder="Eg. 123654"
                value={pin}
                onChange={(e) => setPin(e.target.value)}
              />
            </div>
          </div>
        </div>
        <Button
          text="Submit Payment Details"
          type="submit"
          solid="true"
          buttonStyle={buttonStyle}
        />
      </form>
    </div>
  );
}
