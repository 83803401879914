// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar_sidenav__OwTPB a{
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.sidebar_iconImg__5KFS\\+{
  padding-right: 10px;
  margin-top: -2px;
}
.sidebar_iconImg2__IBjcs{
  padding-left: 10px;
  margin-top: -2px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dashboardApp/layout/sidebar/sidebar.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".sidenav a{\n  color: #fff;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 24px;\n}\n.iconImg{\n  padding-right: 10px;\n  margin-top: -2px;\n}\n.iconImg2{\n  padding-left: 10px;\n  margin-top: -2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sidenav": `sidebar_sidenav__OwTPB`,
	"iconImg": `sidebar_iconImg__5KFS+`,
	"iconImg2": `sidebar_iconImg2__IBjcs`
};
export default ___CSS_LOADER_EXPORT___;
