// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_doughnutChartContainer__aNgU8 {
    display: flex;
    justify-content: flex-start;
    margin-top: 1em;
  }
  
  .style_doughnutChartContent__\\+yOE9 {
    position: relative;
    width: 300px; /* Adjust the width as needed */
  }
  
  .style_centerText__yRvFV {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 0px;
    color: #718096;
  }

  .style_subtext__1tX8j{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #718096;
  }
  .style_hText__p5C2t{
    margin-bottom: 0px;
  }`, "",{"version":3,"sources":["webpack://./src/app/components/dashboardApp/BuyerDoughnutChart/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,2BAA2B;IAC3B,eAAe;EACjB;;EAEA;IACE,kBAAkB;IAClB,YAAY,EAAE,+BAA+B;EAC/C;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,kBAAkB;IAClB,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;EAChB;EACA;IACE,kBAAkB;EACpB","sourcesContent":[".doughnutChartContainer {\n    display: flex;\n    justify-content: flex-start;\n    margin-top: 1em;\n  }\n  \n  .doughnutChartContent {\n    position: relative;\n    width: 300px; /* Adjust the width as needed */\n  }\n  \n  .centerText {\n    position: absolute;\n    top: 45%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    text-align: center;\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 36px;\n    margin-bottom: 0px;\n    color: #718096;\n  }\n\n  .subtext{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: #718096;\n  }\n  .hText{\n    margin-bottom: 0px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"doughnutChartContainer": `style_doughnutChartContainer__aNgU8`,
	"doughnutChartContent": `style_doughnutChartContent__+yOE9`,
	"centerText": `style_centerText__yRvFV`,
	"subtext": `style_subtext__1tX8j`,
	"hText": `style_hText__p5C2t`
};
export default ___CSS_LOADER_EXPORT___;
