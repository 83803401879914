// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tableFooter_showitem__B7gw2 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #4A5568;
    top: 0.2em;
}

.tableFooter_shwbtn__AnVXM{
    padding: 0px 16px;
    background: #EDF2F7;
    border-radius: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dashboardApp/tableFooter/tableFooter.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".showitem {\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n    color: #4A5568;\n    top: 0.2em;\n}\n\n.shwbtn{\n    padding: 0px 16px;\n    background: #EDF2F7;\n    border-radius: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showitem": `tableFooter_showitem__B7gw2`,
	"shwbtn": `tableFooter_shwbtn__AnVXM`
};
export default ___CSS_LOADER_EXPORT___;
