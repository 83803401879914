// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_searchInput__p3\\+lu {
  width: 300px;
  display: flex;
  border: 1px solid rgba(226, 232, 240, 1);
  border-radius: 0px 4px 4px 0px;
}

.styles_iconContainer__\\+MyOS {
  padding: 10px 13px;
  background-color: rgba(226, 232, 240, 1);
}

.styles_searchInput__p3\\+lu input {
  border: none;
  outline: none;
  width: 200px;
  padding-left: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/homeApp/searchIntput/styles.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,wCAAwC;EACxC,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".searchInput {\n  width: 300px;\n  display: flex;\n  border: 1px solid rgba(226, 232, 240, 1);\n  border-radius: 0px 4px 4px 0px;\n}\n\n.iconContainer {\n  padding: 10px 13px;\n  background-color: rgba(226, 232, 240, 1);\n}\n\n.searchInput input {\n  border: none;\n  outline: none;\n  width: 200px;\n  padding-left: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInput": `styles_searchInput__p3+lu`,
	"iconContainer": `styles_iconContainer__+MyOS`
};
export default ___CSS_LOADER_EXPORT___;
