// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_about__GYA-X {
    padding: 48px 288px;
  }
  
  .styles_about__GYA-X h1 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2d3748;
    margin-bottom: 20px;
  }
  
  .styles_about__GYA-X h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #2d3748;
    margin-bottom: 0;
  }
  
  .styles_about__GYA-X p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2d3748;
  }
  
  .styles_about__GYA-X a {
      border: none;
      outline: none;
      background-color: transparent;
      padding: 4px 4px;
      padding-left: 0;
      color: #38a169;
      text-decoration: none;
  }
  
  
  @media (max-width: 991px) {
  
    .styles_about__GYA-X {
      padding: 25px 22px 37px;
    }
  
  }`, "",{"version":3,"sources":["webpack://./src/app/pages/home/privacypolicy/styles.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;EAChB;;EAEA;MACI,YAAY;MACZ,aAAa;MACb,6BAA6B;MAC7B,gBAAgB;MAChB,eAAe;MACf,cAAc;MACd,qBAAqB;EACzB;;;EAGA;;IAEE;MACE,uBAAuB;IACzB;;EAEF","sourcesContent":[".about {\n    padding: 48px 288px;\n  }\n  \n  .about h1 {\n    font-weight: 600;\n    font-size: 24px;\n    line-height: 32px;\n    color: #2d3748;\n    margin-bottom: 20px;\n  }\n  \n  .about h2 {\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 28px;\n    color: #2d3748;\n    margin-bottom: 0;\n  }\n  \n  .about p {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 24px;\n    color: #2d3748;\n  }\n  \n  .about a {\n      border: none;\n      outline: none;\n      background-color: transparent;\n      padding: 4px 4px;\n      padding-left: 0;\n      color: #38a169;\n      text-decoration: none;\n  }\n  \n  \n  @media (max-width: 991px) {\n  \n    .about {\n      padding: 25px 22px 37px;\n    }\n  \n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": `styles_about__GYA-X`
};
export default ___CSS_LOADER_EXPORT___;
