import React, { useState } from "react";
import { Container, Row, Col, Table, Badge } from "react-bootstrap";
import styles from "./customTable.module.css";
import PlaceBid from "../../../components/dashboardApp/PlaceBidInputButton";

const AdminCustomTable = ({
  heading,
  filteredData,
  bids,
  onRowClick,
  type,
  dropDownValue,
}) => {
  
  return (
    <>
      <Table responsive>
        <thead style={{ backgroundColor: "#F7FAFC" }}>
          {type === "auctions"
            ? heading.map((headings, index) => (
                <tr key={index}>
                  <td className={styles.headT}>{headings.auctionID}</td>
                  <td className={styles.headT}>{headings.supplierID}</td>
                  <td className={styles.headT}>{headings.supplierName}</td>
                  <td className={styles.headT}>{headings.herbName}</td>
                  <td className={styles.headT}>{headings.createdDate}</td>
                  {dropDownValue !== "In Review" && (
                    <td className={styles.headT}>{headings.noOfbids}</td>
                  )}
                  <td className={styles.headT}>{headings.quantity}</td>
                  <td className={styles.headT}>{headings.askedPricePerUnit}</td>
                  {dropDownValue !== "In Review" && (
                    <td className={styles.headT}>{headings.highbid}</td>
                  )}
                  <td className={styles.headT}>{headings.status}</td>
                </tr>
              ))
            : type === "supplier"
            ? heading.map((headings, index) => (
                <tr key={index}>
                  <td className={styles.headT}>{headings.supplierID}</td>
                  <td className={styles.headT}>{headings.supplierName}</td>
                  <td className={styles.headT}>{headings.regDate}</td>
                  {dropDownValue === "Approved" ? (
                    <td className={styles.headT}>{headings.noOfAuctions}</td>
                  ) : (
                    <td className={styles.headT}>
                      {headings.participatedAuctions}
                    </td>
                  )}
                  <td className={styles.headT}>{headings.status}</td>
                </tr>
              ))
            : type === "buyers" &&
              heading.map((headings, index) => (
                <tr key={index}>
                  <td className={styles.headT}>{headings.buyerID}</td>
                  <td className={styles.headT}>{headings.buyerName}</td>
                  <td className={styles.headT}>{headings.regDate}</td>
                  {dropDownValue === "Approved" ? (
                    <td className={styles.headT}>{headings.noOfAuctions}</td>
                  ) : (
                    <td className={styles.headT}>
                      {headings.participatedAuctions}
                    </td>
                  )}
                  <td className={styles.headT}>{headings.status}</td>
                </tr>
              ))}
        </thead>
        <tbody>
          {type === "auctions"
            ? filteredData.map((data, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    onRowClick(data.auctionID);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td className={styles.dataT}>{data.auctionID}</td>
                  <td className={styles.dataT}>{data.supplierID}</td>
                  <td className={styles.dataT}>{data.supplierName}</td>
                  <td className={styles.dataT}>
                    <span>{data.herbName}</span>
                  </td>
                  <td className={styles.dataT}>
                    <span>{data.createdDate}</span>
                  </td>
                  {dropDownValue !== "In Review" && (
                    <td className={styles.dataT}>
                      <span>{data.noOfbids}</span>
                    </td>
                  )}
                  <td className={styles.dataT}>
                    <span>{data.quantity}</span>
                  </td>
                  <td className={styles.dataT}>
                    <span>{data.askedPricePerUnit}</span>
                  </td>
                  {dropDownValue !== "In Review" && (
                    <td className={styles.dataT}>
                      <span>{data.highbid}</span>
                    </td>
                  )}
                  <td className={styles.dataT}>
                    <span>{data.status}</span>
                  </td>
                </tr>
              ))
            : type === "supplier"
            ? filteredData.map((data, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    onRowClick(data.supplierID);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td className={styles.dataT}>{data.supplierID}</td>
                  <td className={styles.dataT}>{data.supplierName}</td>
                  <td className={styles.dataT}>
                    <span>{data.regDate}</span>
                  </td>
                  {dropDownValue === "Approved" ? (
                    <td className={styles.dataT}>
                      <span>{data.noOfAuctions}</span>
                    </td>
                  ) : (
                    <td className={styles.dataT}>
                      <span>{data.participatedAuctions}</span>
                    </td>
                  )}
                  <td className={styles.dataT}>
                    <span>{data.status}</span>
                  </td>
                </tr>
              ))
            : type === "buyers" &&
              filteredData.map((data, index) => (
                <tr
                  key={index}
                  onClick={() => {
                    onRowClick(data.buyerID);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td className={styles.dataT}>
                    <span>{data.buyerID}</span>
                  </td>
                  <td className={styles.dataT}>
                    <span>{data.buyerName}</span>
                  </td>
                  <td className={styles.dataT}>
                    <span>{data.regDate}</span>
                  </td>
                  {dropDownValue === "Approved" ? (
                    <td className={styles.dataT}>
                      <span>{data.noOfAuctions}</span>
                    </td>
                  ) : (
                    <td className={styles.dataT}>
                      <span>{data.participatedAuctions}</span>
                    </td>
                  )}
                  <td className={styles.dataT}>
                    <span>{data.status}</span>
                  </td>
                </tr>
              ))}
        </tbody>
      </Table>
    </>
  );
};

export default AdminCustomTable;
