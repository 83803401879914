// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
 
}

body,
html {
  overflow-x: hidden;
  
}

.styles_aboutMedicinalPlants__tASvt {
  padding: 48px 280px;
}
.styles_aboutMedicinalPlants__tASvt h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;

  color: #2d3748;
}

.styles_aboutMedicinalPlants__tASvt a {
  text-decoration: none;
  color: #38a169;
}


@media (max-width: 991px) {
  .styles_aboutMedicinalPlants__tASvt {

  padding: 104px 17px 111px 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/aboutMedicinalPlants/styles.module.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;;AAEZ;;AAEA;;EAEE,kBAAkB;;AAEpB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;;EAEjB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;;AAGA;EACE;;EAEA,8BAA8B;EAC9B;AACF","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n \n}\n\nbody,\nhtml {\n  overflow-x: hidden;\n  \n}\n\n.aboutMedicinalPlants {\n  padding: 48px 280px;\n}\n.aboutMedicinalPlants h1 {\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 32px;\n\n  color: #2d3748;\n}\n\n.aboutMedicinalPlants a {\n  text-decoration: none;\n  color: #38a169;\n}\n\n\n@media (max-width: 991px) {\n  .aboutMedicinalPlants {\n\n  padding: 104px 17px 111px 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aboutMedicinalPlants": `styles_aboutMedicinalPlants__tASvt`
};
export default ___CSS_LOADER_EXPORT___;
