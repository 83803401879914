// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.placebid_placeColor__VQINV{
    background: #FFFFFF;
    border: 1px solid #E2E8F0;
    border-radius: 4px;
}
.placebid_placeColor__VQINV::placeholder {
    color: #A0AEC0;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.placebid_bidBtn__R0TRN {
    background: #EDF2F7;
    border-radius: 6px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1A202C;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/dashboardApp/PlaceBidInputButton/placebid.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,YAAY;AAChB","sourcesContent":[".placeColor{\n    background: #FFFFFF;\n    border: 1px solid #E2E8F0;\n    border-radius: 4px;\n}\n.placeColor::placeholder {\n    color: #A0AEC0;\n    font-style: normal;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 19px;\n}\n.bidBtn {\n    background: #EDF2F7;\n    border-radius: 6px;\n    font-style: normal;\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n    color: #1A202C;\n    border: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeColor": `placebid_placeColor__VQINV`,
	"bidBtn": `placebid_bidBtn__R0TRN`
};
export default ___CSS_LOADER_EXPORT___;
