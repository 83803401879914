// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__3x74l {
  padding: 6px 12px;
  background-color: #f3f3f3;
  outline: none;
  border: 1px solid #cecece;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.styles_button__3x74l span {
  font-weight: 600;
  font-size: 14px;
  padding-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/homeApp/buttonViewAll/styles.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".button {\n  padding: 6px 12px;\n  background-color: #f3f3f3;\n  outline: none;\n  border: 1px solid #cecece;\n  border-radius: 6px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n}\n\n.button span {\n  font-weight: 600;\n  font-size: 14px;\n  padding-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__3x74l`
};
export default ___CSS_LOADER_EXPORT___;
