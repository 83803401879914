import React from "react";
import styles from "./styles.module.css";
import PaymentForm from "./paymentForm";

export default function Payment() {
  return (
    <div className={styles.payment}>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <h3>Make Payment</h3>
          <span>STEP 1</span>
        </div>
        <div className={styles.headerRight}>
          <span>Make payment via NEFT on the given account details</span>
        </div>
      </div>
      <div className={styles.flexCardContainer}>
        <div className={styles.card}>
          <div className={styles.rowLeft}>
            <span style={{ fontWeight: 500 }}>Your bid / kg</span>
            <span>₹1000</span>
          </div>
          <div className={styles.rowLeft}>
            <span style={{ fontWeight: 500 }}>Your payment</span>
            <span>₹10,00,000</span>
          </div>
          <div className={styles.rowLeft}>
            <span style={{ fontWeight: 500 }}>GST</span>
            <span>18%</span>
          </div>
          <div className={styles.rowLeft}>
            <span style={{ fontWeight: 600 }}>Grand Total</span>
            <span style={{ fontWeight: 600 }}>₹11,80,000</span>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.rowRight}>
            <span style={{ fontWeight: 500 }}>Beneficiery's name</span>
            <span>Agnext</span>
          </div>
          <div className={styles.rowRight}>
            <span style={{ fontWeight: 500 }}>
              Beneficiery's account number
            </span>
            <span>1234567890</span>
          </div>
          <div className={styles.rowRight}>
            <span style={{ fontWeight: 500 }}>Beneficiery's bank name</span>
            <span>State Bank of India</span>
          </div>
          <div className={styles.rowRight}>
            <span style={{ fontWeight: 500 }}>
              Beneficiery's bank branch name
            </span>
            <span>Mohali</span>
          </div>
          <div className={styles.rowRight}>
            <span style={{ fontWeight: 500 }}>Beneficiery's IFSC code</span>
            <span>SBIN0000001</span>
          </div>
        </div>
      </div>
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <h3>Fill payment and delivery details</h3>
          <span>STEP 2</span>
        </div>
        <div className={styles.headerRight}>
          <span>
            Once you have made your payment, please fill the following form
          </span>
        </div>
      </div>
      <PaymentForm />
    </div>
  );
}
