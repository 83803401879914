// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_accordian__U95W0 {
}

.styles_title__2tk5J {
  display: flex;
  padding: 10px 12px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.styles_title__2tk5J h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1a202c;
}

.styles_details__kV7Xm {
  padding: 16px;
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/homeApp/accordian/styles.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,aAAa;AACf","sourcesContent":[".accordian {\n}\n\n.title {\n  display: flex;\n  padding: 10px 12px;\n  justify-content: space-between;\n  align-items: center;\n  cursor: pointer;\n}\n\n.title h3 {\n  font-weight: 500;\n  font-size: 16px;\n  line-height: 24px;\n  color: #1a202c;\n}\n\n.details {\n  padding: 16px;\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordian": `styles_accordian__U95W0`,
	"title": `styles_title__2tk5J`,
	"details": `styles_details__kV7Xm`
};
export default ___CSS_LOADER_EXPORT___;
