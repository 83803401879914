// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


    #bankdetails #btn_holder{
        justify-content: center;
    }

    #btns_holder{
        justify-content: center;
        flex-wrap: wrap;
    }
    #dand div:has(input){
     padding: 20px!important; 
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;      

    }
  


    @media only screen and (max-width:670px){
        #acc_holder div:has(input){
            width: 100%;
        }
        #flexdiv{
            flex-wrap: wrap;
            gap: 20px;
            & div:has(input) {
                width: 100%;
            }
        }
      
    }`, "",{"version":3,"sources":["webpack://./src/app/pages/home/RegistrationPage/BankDetails/BankDetails.css"],"names":[],"mappings":";;;IAGI;QACI,uBAAuB;IAC3B;;IAEA;QACI,uBAAuB;QACvB,eAAe;IACnB;IACA;KACC,uBAAuB;KACvB,aAAa;KACb,sBAAsB;KACtB,uBAAuB;KACvB,mBAAmB;;IAEpB;;;;IAIA;QACI;YACI,WAAW;QACf;QACA;YACI,eAAe;YACf,SAAS;YACT;gBACI,WAAW;YACf;QACJ;;IAEJ","sourcesContent":["\n\n\n    #bankdetails #btn_holder{\n        justify-content: center;\n    }\n\n    #btns_holder{\n        justify-content: center;\n        flex-wrap: wrap;\n    }\n    #dand div:has(input){\n     padding: 20px!important; \n     display: flex;\n     flex-direction: column;\n     justify-content: center;\n     align-items: center;      \n\n    }\n  \n\n\n    @media only screen and (max-width:670px){\n        #acc_holder div:has(input){\n            width: 100%;\n        }\n        #flexdiv{\n            flex-wrap: wrap;\n            gap: 20px;\n            & div:has(input) {\n                width: 100%;\n            }\n        }\n      \n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
