// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_allAuctions__9u8Cn {
    padding: 48px 64px;
}

.styles_pageHeader__v5gMa {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.styles_pageHeader__v5gMa .styles_title__jPskt h1 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
}

.styles_inputContainer__LeuZG {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    
}

.styles_auctionListcontainer__g63y3 {
    width: 100%;
    padding-top: 34px;
}

.styles_auctionList__w196j {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    /* justify-content: space-between; */
    gap: 24px;
}

.styles_centerText__ObGMV {
    text-align: center;
  }`, "",{"version":3,"sources":["webpack://./src/app/pages/home/allAuctions/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,eAAe;;AAEnB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,WAAW;IACX,oCAAoC;IACpC,SAAS;AACb;;AAEA;IACI,kBAAkB;EACpB","sourcesContent":[".allAuctions {\n    padding: 48px 64px;\n}\n\n.pageHeader {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n.pageHeader .title h1 {\n    font-size: 20px;\n    line-height: 28px;\n    font-weight: 700;\n}\n\n.inputContainer {\n    display: flex;\n    gap: 16px;\n    flex-wrap: wrap;\n    \n}\n\n.auctionListcontainer {\n    width: 100%;\n    padding-top: 34px;\n}\n\n.auctionList {\n    display: flex;\n    flex-wrap: wrap;\n    width: 100%;\n    /* justify-content: space-between; */\n    gap: 24px;\n}\n\n.centerText {\n    text-align: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"allAuctions": `styles_allAuctions__9u8Cn`,
	"pageHeader": `styles_pageHeader__v5gMa`,
	"title": `styles_title__jPskt`,
	"inputContainer": `styles_inputContainer__LeuZG`,
	"auctionListcontainer": `styles_auctionListcontainer__g63y3`,
	"auctionList": `styles_auctionList__w196j`,
	"centerText": `styles_centerText__ObGMV`
};
export default ___CSS_LOADER_EXPORT___;
