// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
body,
html {
  overflow-x: hidden;
  
}





.styles_login__sc0t9 {
  padding: 48px;
}




.styles_registrationHeader__XTjGY {
  text-align: center;
  color: #2d3748;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

.styles_accountDetails__xsfd2 {
  color: #2d3748;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.styles_verifyAccountDetails__jwq\\+I {
  color: #718096;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
}

.styles_accountType__FZ7hX {
  color: #2d3748;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 32px;
  
}
.styles_topAlign__DO11I {
  margin-top: 16px;
}

.styles_personalDetails__AL47C {
  color: #2d3748;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.styles_termsAndConditions__53FvU {
  color: #2d3748;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.styles_termsAndConditionsLink__mLqQm {
    color: #38A169;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }


  
 `, "",{"version":3,"sources":["webpack://./src/app/pages/home/RegistrationPage/styles.module.css"],"names":[],"mappings":";AACA;;EAEE,kBAAkB;;AAEpB;;;;;;AAMA;EACE,aAAa;AACf;;;;;AAKA;EACE,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;;AAElB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,iBAAiB;EACnB","sourcesContent":["\nbody,\nhtml {\n  overflow-x: hidden;\n  \n}\n\n\n\n\n\n.login {\n  padding: 48px;\n}\n\n\n\n\n.registrationHeader {\n  text-align: center;\n  color: #2d3748;\n  font-weight: 600;\n  font-size: 24px;\n  line-height: 32px;\n}\n\n.accountDetails {\n  color: #2d3748;\n  font-weight: 500;\n  font-size: 20px;\n  line-height: 28px;\n}\n\n.verifyAccountDetails {\n  color: #718096;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  margin-top: 8px;\n}\n\n.accountType {\n  color: #2d3748;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n  margin-top: 32px;\n  \n}\n.topAlign {\n  margin-top: 16px;\n}\n\n.personalDetails {\n  color: #2d3748;\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 24px;\n}\n\n.termsAndConditions {\n  color: #2d3748;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n}\n\n.termsAndConditionsLink {\n    color: #38A169;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n  }\n\n\n  \n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login": `styles_login__sc0t9`,
	"registrationHeader": `styles_registrationHeader__XTjGY`,
	"accountDetails": `styles_accountDetails__xsfd2`,
	"verifyAccountDetails": `styles_verifyAccountDetails__jwq+I`,
	"accountType": `styles_accountType__FZ7hX`,
	"topAlign": `styles_topAlign__DO11I`,
	"personalDetails": `styles_personalDetails__AL47C`,
	"termsAndConditions": `styles_termsAndConditions__53FvU`,
	"termsAndConditionsLink": `styles_termsAndConditionsLink__mLqQm`
};
export default ___CSS_LOADER_EXPORT___;
