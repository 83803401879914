// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_heading__GtPXc{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
}
.style_rowStyle__oWJ93{
    margin-left: 0px;
    padding: 1em;
}
.style_pheadingtext__kMmTG {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    color: #4A5568;
}
.style_colStyle__DywnK{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}
.style_paddingCol__7k-BB{
    padding: 1em 1em 1em 1.5em;
}

.style_borderbtm__N66Ch{
    border-bottom: 1px solid #E2E8F0;
}
.style_headingText__jAnQv{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2D3748;
}
.style_valueText__eaif8{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2D3748;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/sellerdashboardApp/auctionDetail/quality/style.module.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,2CAA2C;IAC3C,kBAAkB;AACtB;AACA;IACI,0BAA0B;AAC9B;;AAEA;IACI,gCAAgC;AACpC;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;AACA;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".heading{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 600;\n    font-size: 18px;\n    line-height: 28px;\n    color: #000000;\n}\n.rowStyle{\n    margin-left: 0px;\n    padding: 1em;\n}\n.pheadingtext {\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 700;\n    font-size: 12px;\n    line-height: 16px;\n    letter-spacing: 0.05em;\n    color: #4A5568;\n}\n.colStyle{\n    background: #FFFFFF;\n    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);\n    border-radius: 8px;\n}\n.paddingCol{\n    padding: 1em 1em 1em 1.5em;\n}\n\n.borderbtm{\n    border-bottom: 1px solid #E2E8F0;\n}\n.headingText{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 20px;\n    color: #2D3748;\n}\n.valueText{\n    font-family: 'Inter';\n    font-style: normal;\n    font-weight: 400;\n    font-size: 14px;\n    line-height: 20px;\n    color: #2D3748;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": `style_heading__GtPXc`,
	"rowStyle": `style_rowStyle__oWJ93`,
	"pheadingtext": `style_pheadingtext__kMmTG`,
	"colStyle": `style_colStyle__DywnK`,
	"paddingCol": `style_paddingCol__7k-BB`,
	"borderbtm": `style_borderbtm__N66Ch`,
	"headingText": `style_headingText__jAnQv`,
	"valueText": `style_valueText__eaif8`
};
export default ___CSS_LOADER_EXPORT___;
