// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_dropdownContainer__a23nj {
    padding: 10px;
    border: 1px solid rgba(226, 232, 240, 1);
    border-radius: 6px;
}

.styles_dropdown__B3MvI {
    border: none;
    outline: none;
    background-color: transparent;
}

.styles_dropdown__B3MvI option {
    width: 160px;
    display: inline-block;
    padding: 48px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/homeApp/dropdown/styles.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,aAAa;AACjB","sourcesContent":[".dropdownContainer {\n    padding: 10px;\n    border: 1px solid rgba(226, 232, 240, 1);\n    border-radius: 6px;\n}\n\n.dropdown {\n    border: none;\n    outline: none;\n    background-color: transparent;\n}\n\n.dropdown option {\n    width: 160px;\n    display: inline-block;\n    padding: 48px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdownContainer": `styles_dropdownContainer__a23nj`,
	"dropdown": `styles_dropdown__B3MvI`
};
export default ___CSS_LOADER_EXPORT___;
