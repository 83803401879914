import React from "react";
import General from "../../../../components/dashboardApp/GeneralDetail";

const Supplier = ({listHeading, listData}) => {
    return (
        <>
            <General />
        </>
    );
}

export default Supplier;